<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12">
        <label>Anchor</label>
        #<input
          class="form-control inlined"
          v-model="anchor"
          v-on:change="notifyChanges"
        />
      </div>
    </div>

    <div class="row">
        <div class="col-xs-12">
          <h4>Instellingen</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-2">
            Achtergrond afbeelding
        </div>
        <div class="col-xs-10">
            <ImageUploadComponent
          label="Afbeelding"
          v-model="backgroundImage"
          containerStyle="float:left;border:1px solid green;"
          layout="horizontal"
          v-on:change="notifyChanges"
        />
        </div>
    </div>
    <div class="row">
        <div class="col-xs-2">
            Achtergrond kleur header
        </div>
        <div class="col-xs-10">
            Kies: <select v-bind:value="colorSelect" v-on:change="onChangeColorSelect($event)">
                <option value="custom">custom</option>
                <option value="grijs">Grijs</option>
            </select><br>

            <chrome-picker v-if="colorSelect == 'custom'" v-model="backgroundColor" acceptLabel="ok"></chrome-picker>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-2">
            Blok-titel
        </div>
        <div class="col-xs-10">
            <KendoValueComponent v-model="title" :tools="tools" v-on:change="notifyChanges" />
        </div>
    </div>
    <div class="row">
        <div class="col-xs-2">
            Zoekveld tekst
        </div>
        <div class="col-xs-10">
            <KendoValueComponent v-model="searchPlaceholder" :tools="tools" v-on:change="notifyChanges" />
        </div>
    </div>
      
    <div class="row">
      <div class="col-xs-6 col-md-4 col-lg-3">
        <h4>Onderwerpen</h4>
        <div class="row">
          <div class="col-xs-12">
            <input
              type="text"
              class="form-control inlined"
              v-on:change="notifyChanges"
              v-on:input="notifyChanges"
              v-model="subjectValue"
              placeholder="Onderwerp"
              v-on:keydown.enter.prevent="addSubject"
            />
            <button class="btn btn-sm btn-primary" @click.prevent="addSubject">
              <i class="fa fa-plus"></i>
            </button>
          </div>

          <datalist id="subjects">
            <option v-for="(subject, index) in subjects" :value="subject.tag">
              {{ subject.tag }}
            </option>
          </datalist>
          <div class="col-xs-12" v-for="(subject, index) in subjects">
            <input
              type="text"
              class="form-control inlined"
              v-on:change="notifyChanges"
              v-on:input="notifyChanges"
              v-model="subject.tag"
              disabled
            />
            <button
              class="btn btn-sm btn-danger"
              @click.prevent="removeSubject(index)"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-xs-6 col-md-4 col-lg-3">
        <h4>Tags</h4>
        <div class="row">
          <div class="col-xs-12">
            <input
              type="text"
              class="form-control inlined"
              v-on:change="notifyChanges"
              v-on:input="notifyChanges"
              v-model="tagValue"
              placeholder="Tag"
              v-on:keydown.enter.prevent="addTag"
            />
            <button @click.prevent="addTag" class="btn btn-sm btn-primary">
              <i class="fa fa-plus"></i>
            </button>
          </div>
          <datalist id="tags">
            <option v-for="(tag, index) in tags" :value="tag.tag">
              {{ tag.tag }}
            </option>
          </datalist>
          <div class="col-xs-12" v-for="(tag, index) in tags">
            <input
              type="text"
              class="form-control inlined"
              v-on:change="notifyChanges"
              v-on:input="notifyChanges"
              v-model="tag.tag"
              disabled
            />
            <button
              class="btn btn-sm btn-danger"
              @click.prevent="removeTag(index)"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
        <h4>Content</h4>
    <div class="block" v-for="(block, index) in blocks">
      <div class="row">
        <button class="btn btn-sm btn-danger delete-btn" @click.prevent="removeBlock(index)"><i class="fa fa-trash-alt"></i></button>
        <button class="btn btn-info btn-sm" v-if="index !=0" @click.prevent="moveItem(index, -1)"><i class="fas fa-arrow-up"></i></button>
        <button class="btn btn-info btn-sm" v-if="index != blocks.length-1" @click.prevent="moveItem(index, 1)"><i class="fas fa-arrow-down"></i></button>
      </div>
      <div class="row">
        <ImageUploadComponent
          label="Afbeelding"
          v-model="block.image"
          containerStyle="float:left;border:1px solid green;"
          layout="horizontal"
          v-on:change="notifyChanges"
        />
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
          <label>Titel</label>
          <input
            type="text"
            v-model="block.title"
            v-on:change="notifyChanges"
            v-on:input="notifyChanges"
            placeholder="Titel"
            class="form-control"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
          <label>Text</label>
          <KendoValueComponent
            v-model="block.text"
            :tools="tools"
            v-on:change="notifyChanges"
            style="margin-top: -15px"
          />
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
          <ButtonComponent v-model="block.button" v-on:change="notifyChanges" />
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
          <label>Onderwerpen</label>
          <select
            class="form-control"
            @change="addBlockTag(block.subjects, $event)"
            style="min-width: 100px"
          >
            <option value=""></option>
            <option
              v-for="(tag, index) in subtract(subjects, block.subjects)"
              :value="tag.tag"
            >
              {{ tag.tag }}
            </option>
          </select>
          <div>
            <template
              v-for="blockSubject in block.subjects"
              v-if="!blockSubject.deleted"
            >
              <input
                type="text"
                :value="blockSubject.tag"
                disabled
                class="form-control inlined" />
              <button
                style="margin-right: 5px"
                class="btn btn-sm btn-danger"
                @click.prevent="
                  removeTagFromBlock(block.subjects, blockSubject)
                "
              >
                <i class="fas fa-trash-alt"></i></button
            ></template>
          </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
          <label>Tags</label>
          <select
            class="form-control"
            @change="addBlockTag(block.tags, $event)"
            style="min-width: 100px"
          >
            <option value=""></option>
            <option
              v-for="(tag, index) in subtract(tags, block.tags)"
              :value="tag.tag"
            >
              {{ tag.tag }}
            </option>
          </select>
          <div>
            <template
              v-for="blockSubject in block.tags"
              v-if="!blockSubject.deleted"
            >
              <input
                type="text"
                :value="blockSubject.tag"
                disabled
                class="form-control inlined" />
              <button
                style="margin-right: 5px"
                class="btn btn-sm btn-danger"
                @click.prevent="removeTagFromBlock(block.tags, blockSubject)"
              >
                <i class="fas fa-trash-alt"></i></button
            ></template>
          </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
          <label>Keywords</label>
          <input
            type="text"
            class="form-control"
            v-on:change="notifyChanges"
            v-on:input="notifyChanges"
            placeholder="Keyword"
            v-on:keydown.enter.prevent="
              addBlockKeyword(block, $event)
            "
          />
          <template
            v-for="(kw, index) in block.keywords">
          <input
            type="text"
            class="form-control inlined"
            v-on:change="notifyChanges"
            v-on:input="notifyChanges"
            :value="kw"
            disabled
          />
          <button
            class="btn btn-sm btn-danger"
            @click.prevent="deleteBlockKeyword(block, index)"
          >
            <i class="fas fa-trash-alt"></i>
          </button>
          </template>
        </div>
      </div>
    </div>
    </div>
    </div>
    <div class="row" style="margin-top: 15px">
      <button
        style="float: right; margin-right: 15px"
        class="btn btn-sm btn-primary"
        @click.prevent="addBlock"
      >
        <i class="fa fa-plus"></i>
      </button>
    </div>
  </div>
</template>

<script type="application/javascript">
/* https://github.com/xiaokaike/vue-color */
var Picker = require('vue-color/src/components/Chrome.vue').default;
export default {
    components: {
            'ChromePicker': Picker,
    },
    computed: {
        colorSelect: function()
        {
            if (typeof this.backgroundColor === 'string' || this.backgroundColor instanceof String)
            {
                return this.backgroundColor;
            }
            return 'custom';
        }
    },
  mounted() {
    for (let k of this.dataKeys)
      this.$watch(k, this.notifyChanges, { immediate: true });
  },
  methods: {
    onChangeColorSelect: function(event)
    {
        if (event.target.value == 'custom')
        {
            this.backgroundColor = { r: 255, g: 0, b: 0 };
        }
        else
        {
            this.backgroundColor = event.target.value;
        }
    },
    notifyChanges() {
      let obj = {};
      for (let k of this.dataKeys) Vue.set(obj, k, this[k]);
      this.$emit("change", obj);
    },
    addSubject() {
      if (this.subjectValue == "") return;
      this.subjects.push({ id: null, tag: this.subjectValue });
      this.notifyChanges();
      this.subjectValue = "";
    },
    removeSubject(index) {
      this.subjects.splice(index, 1);
      this.notifyChanges();
    },
    addTag() {
      if (this.tagValue == "") return;
      this.tags.push({ id: null, tag: this.tagValue });
      this.notifyChanges();
      this.tagValue = "";
    },
    removeTag(index) {
      this.tags.splice(index, 1);
      this.notifyChanges();
    },
    addBlock() {
      this.blocks.push({
        subjects: [],
        tags: [],
        tag: "",
        keywords: [],
        subject: "",
        text: "",
        title: "",
        button: { label: "", url: "" },
        image: { path: "", alt: "", visible: true },
      });
    },
    addBlockKeyword(block, ev)
    {
        block.keywords.push(ev.target.value);
        ev.target.value='';
        this.notifyChanges();
    },
    deleteBlockKeyword(block, index)
    {
        block.keywords.splice(index, 1);
        this.notifyChanges();
    },
    addBlockSubject(block) {
      if (block.subject == "") return;
      block.subjects.push({ tag: block.subject });
      block.subject = "";
    },
    getTagIndex(list, tag) {
      for (let i = 0; i < list.length; ++i) {
        if (list[i].tag == tag) return i;
      }
      return -1;
    },
    subtract(a, b) {
      let c = [];
      for (let it of a) {
        let has = false;
        for (let other of b) {
          if (other.tag == it.tag && null == other.deleted) {
            has = true;
            break;
          }
        }
        if (!has) c.push(it);
      }
      return c;
    },
    addBlockTag(list, event) {
      let tagIndex = this.getTagIndex(list, event.target.value);
      if (tagIndex != -1) {
        Vue.set(list[tagIndex], "deleted", null);
        event.target.value = "";
        return;
      }

      if (event.target.value == "") return;
      list.push({ tag: event.target.value });
      event.target.value = "";
    },
    removeBlock(index) {
      this.blocks.splice(index, 1);
    },
    moveItem(index, direction)
    {
        let old_index = index;
        let new_index = old_index + direction;
        if(new_index < 0)
            return;
        if(new_index >= this.blocks.length)
            return;
        this.blocks.splice(new_index, 0, this.blocks.splice(old_index, 1)[0]);
    },
    removeTagFromBlock(list, object) {
      for (let i = 0; i < list.length; ++i) {
        if (list[i].tag == object.tag) {
          Vue.set(list[i], "deleted", true);
          break;
        }
      }
    },
  },
  data() {
    let obj = {
      tools: [
        "cut",
        "copy",
        "paste",
        "bold",
        "italic",
        "superscript",
        "underline",
        "insertUnorderedList",
        "insertOrderedList",
        "createLink",
        "unlink",
        {
          name: "custom",
          tooltip: "Wordbreak toevoegen",
          exec: function (e) {
            var editor = $(this).data("kendoEditor");
            editor.exec("inserthtml", { value: "&shy;" });
          },
        },
        "cleanFormatting",
      ],
      dataKeys: Object.keys(this.data),
      subjectValue: "",
      tagValue: ""
    };
    for (let k of obj.dataKeys) Vue.set(obj, k, this.data[k]);
    return obj;
  },
  props: ["data"],
};
</script>

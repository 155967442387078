<template>
<form>
<div class="widgets" v-for="dbWidget in data.widgets">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>Widget {{ dbWidget.name }}</h5>
                    <div class="ibox-tools">
                        <a class="collapse-link">
                            <i class="fa fa-chevron-up"></i>
                        </a>
                    </div>
                </div>

                <div class="ibox-content" v-if="dbWidget.name == 'footer_widget_acc'">

                        <h3>Footer Accountant</h3>

                        <KendoValueComponent v-model="dbWidget.json.widget.titel" label="Footer titel" :tools="tools" />
                        <KendoValueComponent v-model="dbWidget.json.widget.contents" label="Content gedeelte" :tools="tools" />

                        <ButtonComponent v-model="dbWidget.json.widget.button" />
                        <ImageUploadComponent label="Header afbeelding" @change="onChange($event)" v-model="dbWidget.json.widget.image" />
                </div>
                <div class="ibox-content" v-if="dbWidget.name == 'footer_widget_mkb'">

                        <h3>Footer MKB</h3>

                        <KendoValueComponent v-model="dbWidget.json.widget.titel" label="Footer titel" :tools="tools" />
                        <KendoValueComponent v-model="dbWidget.json.widget.contents" label="Content gedeelte" :tools="tools" />

                        <ButtonComponent v-model="dbWidget.json.widget.button" />
                        <ImageUploadComponent label="Header afbeelding" @change="onChange($event)" v-model="dbWidget.json.widget.image" />
                </div>
                <div class="ibox-content" v-if="dbWidget.name == 'header_script'">

                        <h3>Header script/html</h3>

                        script/html: <textarea class="form-control" v-model="dbWidget.json.widget.script" @change.prevent="onChange($event)" @input="onChange($event)" placeholder=""></textarea>
                </div>
                <div class="ibox-content" v-if="dbWidget.name == 'footer'">

                        <h3>Footer</h3>

                        URL: <input list="pages" class="form-control" v-model="dbWidget.json.widget.url" @change.prevent="onChange($event)" @input="onChange($event)" placeholder="">
                        <ImageUploadComponent label="Footer afbeelding" @change="onChange($event)" v-model="dbWidget.json.widget.image" />
                </div>
            </div>
        </div>
    </div>
</div>
<div class="form-group row">
    <div class="offset-4 col-8">
    <button name="submit" type="submit" class="btn btn-primary" @click.prevent="submitChanges">Opslaan</button>
    </div>
</div>
<pre style="display: none;">{{ JSON.stringify(data, null, 2) }}</pre>
</form>

</template>

<script type="application/javascript">
    export default {
        mounted()
        {
            //for(let k of this.dataKeys)
            //    this.$watch(k, this.notifyChanges, { immediate: true });
        },
        methods: {
            notifyChanges()
            {

            },
            onChange()
            {
                
            },
            submitChanges()
            {
                const URL = '/widgets';
                axios.post(URL, this.data).then((response) => {
                    console.log(response);
                }).catch((err) => {
                    alert("Er is iets fout gegaan met het ophalen van de paginas. Error: " + err);
                });
            }
        },
        data()
        {
            let obj = {
                tools: ["cut", "copy", "paste", "bold", "italic", "superscript", "underline", "insertUnorderedList", "insertOrderedList", "createLink", "unlink", "cleanFormatting","viewHtml"],
            };

            return obj;
        },
        props: ['data']
    }
</script>

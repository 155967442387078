<template>
<div class="ButtonComponent">
    <input type="checkbox" class="form-check-input" :id="'abtesting-checkbox-' + id" v-model="value_.abTestButtonsEnabled" />
    <label class="form-check-label" :for="'abtesting-checkbox-' + id">Verschillende knoppen proberen (A/B Testen)</label>

    <div v-if="value_.abTestButtonsEnabled">
        <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <template v-for="(it, index) in value_.abTestButtons">
                    <button class="nav-link" v-bind:class="{'active': index == activeTabIndex}" @click.prevent="activeTabIndex = index">{{ it.abTestVariant }}</button>
                    <button class="nav-link" @click.prevent="removeABTestVariant(index)">-</button>
                </template>
                <button class="nav-link" @click.prevent="addABTestVariant">+</button>
            </div>
        </nav>
        <div class="tab-content" id="nav-tabContent" v-if="(index == activeTabIndex)" v-for="(it, index) in value_.abTestButtons">
                <div class="form-group">
                    <KendoValueComponent v-model="it.label" label="Knop tekst" @change="notifyChanges" :tools="tools" />
                </div>
                <div class="form-group">
                    <label :for="'button-editx'+id">A/B test naam</label>
                    <input type="text" class="form-control" :id="'button-editx'+id" v-model="it.abTestNaam" @change.prevent="notifyChanges" @input="notifyChanges">
                </div>
                <div class="form-group">
                    <label :for="'button-edity'+id">A/B test variant</label>
                    <input type="text" class="form-control" :id="'button-edity'+id" v-model="it.abTestVariant" @change.prevent="notifyChanges" @input="notifyChanges">
                </div>
                <div class="form-group">
                    <label :for="'button-editz'+id">CTA-button</label>
                    <select class="form-control" @change="notifyChanges" style="min-width: 100px" v-model="it.ctaButton" :id="'button-editz'+id">
                        <option value="">Standaard</option>
                        <option value="cta-scrollt-mee">CTA-button scrollt mee</option>
                        <option value="cta-klopt-als-een-hart">CTA-button klopt als een hart</option>
                        <option value="cta-aangepaste-vorm-en-tekst">CTA-button aangepaste vorm en tekst</option>
                    </select>
                </div>
                <KendoValueComponent v-model="it.cta_aangepaste_tekst" label="CTA-button aangepaste tekst" @change="notifyChanges" :tools="tools" v-if="it.ctaButton == 'cta-aangepaste-vorm-en-tekst'" />
                <div class="form-group">
                    <label :for="'button-edityz'+id">A/B test percentage</label>
                    <input type="number" class="form-control" :id="'button-edityz'+id" v-model="it.abTestPercentage" @change.prevent="notifyChanges" @input="notifyChanges">
                </div>
                <div class="form-group" v-if="it.label && it.label.length > 0">
                    <label :for="'button-edit2'+id">Knop link</label>
                    <input list="pages" class="form-control" :id="'button-edit2'+id" v-model="it.url" @change.prevent="notifyChanges" @input="notifyChanges" placeholder="https://google.com">
                </div>
                <div class="form-group" v-if="it.label && it.label.length > 0">
                    <a :href="it.url" target="_blank" :style="overrideStyle" style=""><div style="display: inline-block; background:#26a8e1;color:#fff;padding:6px;border-radius:15px;border:1px solid #4b9bbe;" v-html="it.label"></div></a>
                </div>
        </div>
    </div>
    <div v-else>
        <div class="form-group">
            <label :for="'button-edit'+id">Knop tekst</label>
            <input type="text" class="form-control" :id="'button-edit'+id" v-model="value_.label" @change.prevent="notifyChanges" @input="notifyChanges" :placeholder="(value_.label && value_.label.length > 0) ? 'Knop 1' : 'Uitgeschakeld'">
        </div>
        <div class="form-group" v-if="value_.label && value_.label.length > 0">
            <label :for="'button-edit2'+id">Knop link</label>
            <input list="pages" class="form-control" :id="'button-edit2'+id" v-model="value_.url" @change.prevent="notifyChanges" @input="notifyChanges" placeholder="https://google.com">
        </div>
        <div class="form-group" v-if="value_.label && value_.label.length > 0">
            Achtergrond kleur:<br />
            <ColorPicker v-model="value_.backgroundColor" v-on:input="notifyChanges"></ColorPicker>
        </div>
        <div class="form-group" v-if="value_.label && value_.label.length > 0">
            <a :href="value_.url" target="_blank" :style="overrideStyle" style="background:#26a8e1;color:#fff;padding:6px;border-radius:15px;border:1px solid #4b9bbe;">{{ value_.label }}</a>
        </div>
    </div>
</div>
</template>

<script type="application/javascript">
    export default {
        props: ['value', 'overrideStyle'],
        data()
        {
            var copyProp = this.value;

            if(copyProp.abTestButtons == null || !Array.isArray(copyProp.abTestButtons))
                copyProp.abTestButtons = [];
            if(copyProp.abTestButtonsEnabled == null)
                copyProp.abTestButtonsEnabled = false;
            if(copyProp.backgroundColor == null)
                copyProp.backgroundColor = "";

            return {
                id: Date.now() + '_' + parseInt(Math.random()*1000),
                value_: copyProp,
                activeTabIndex: 0,
                tools: ["cut", "copy", "paste", "bold", "italic", "superscript", "underline", "insertUnorderedList", "insertOrderedList", "createLink", "unlink", {
                        name: "custom",
                        tooltip: "Wordbreak toevoegen",
                        exec: function (e) {
                            var editor = $(this).data("kendoEditor");
                            editor.exec("inserthtml", { value: "&shy;" });
                        }
                    }, "cleanFormatting"]
            };
        },
        computed: {
            colorSelect: function()
            {
                if (typeof this.value_.backgroundColor === 'string' || this.value_.backgroundColor instanceof String)
                {
                    return this.value_.backgroundColor;
                }
                return 'custom';
            }
        },
        watch: {
          value: {
            handler: function(newValue, oldValue)
            {
                this.value_ = JSON.parse(JSON.stringify(newValue));
            },
            immediate: true,
            deep: true
          },
          value_: {
            handler: function(newValue, oldValue)
            {
                //this.notifyChanges();
            },
            deep: true
          }
        },
        mounted()
        {
            
        },
        methods:
        {
            notifyChanges()
            {
                console.log('value emit '+this.value_.abTestButtonsEnabled);
                this.$emit('input', this.value_);
                this.$emit('change', this.value_);
            },
            removeABTestVariant(index)
            {
                this.value_.abTestButtons.splice(index, 1);_
            },
            addABTestVariant()
            {
                let variant = "Variant " + (this.value_.abTestButtons.length + 1);
                this.value_.abTestButtons.push({
                    label: "Tekst",
                    url: "https://google.com",
                    abTestNaam: "A/B Test 1",
                    abTestVariant: variant,
                    abTestPercentage: 100
                });
                this.notifyChanges();
            }
        }
    }
</script>

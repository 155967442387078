<template>
    <div class="color-picker">
        <select v-bind:value="colorSelect" v-on:change="onChangeColorSelect($event)">
            <option value="">--geen--</option>
            <option value="custom">custom</option>
            <option value="orange">Oranje</option>
            <option value="blue">Blauw</option>
            <option value="bg-1">Thema 1</option>
            <option value="bg-2">Thema 2</option>
        </select><br>

        <chrome-picker v-if="colorSelect == 'custom'" v-model="color" acceptLabel="ok"></chrome-picker>
    </div>
</template>

<script type="application/javascript">
    /* https://github.com/xiaokaike/vue-color */
    var Picker = require('vue-color/src/components/Chrome.vue').default;
     
    export default {
        components: {
            'ChromePicker': Picker,
        },
        props: ['value'],
        data()
        {
            var data = {};
            if (this.value == "" || this.value == undefined)
            {
                data.color = "";
            }
            else
            {
                data.color = this.value;
            }

            return data;
        },
        computed: {
            colorSelect: function()
            {
                if (typeof this.color === 'string' || this.color instanceof String)
                {
                    return this.color;
                }
                return 'custom';
            }
        },
        watch: {
            color: {
              handler(newValue, oldValue) {
                this.$emit('input', newValue);
              },
              deep: true
            }
        },
        methods: {
            onChangeColorSelect: function(event)
            {
                if (event.target.value == 'custom')
                {
                    this.color= { r: 255, g: 0, b: 0 };
                }
                else
                {
                    this.color = event.target.value;
                }
            }
        }
    }
</script>
<template>
<div>
    <datalist id="pages">
        <option v-for="page in pages" :value="page.url">{{ page.titel }}</option>
    </datalist>

    <h2>Kies een blok om toe te voegen</h2>
    <div class="block-template-selector">
      <div v-for="(value, propertyName) in templates">
        <img class="preview" v-if="blockInfo[propertyName]" :src="'/img/'+blockInfo[propertyName].thumb" v-on:click="selectedBlockTemplate = propertyName; onComponentAdded();"><br />
      </div>
    </div>

    <!--<div class="form-inline">
<label>Kies een blok om toe te voegen<br />
    <select v-model="selectedBlockTemplate" class="form-control" aria-label="Selecteer welk type blok u wilt toevoegen.">
        <option v-for="(value, propertyName) in templates">{{ blockInfo[propertyName].label }}</option>
    </select></label>

    <img v-if="blockInfo[selectedBlockTemplate]" :src="'/img/'+blockInfo[selectedBlockTemplate].image">

    <button class="btn btn-sm btn-primary" @click.prevent="onComponentAdded"><i class="fas fa-plus"></i></button>
    </div>-->
    <!--<div v-on:dragenter.self="dragEnter(index, $event)" style="cursor:move;margin-top:15px;" v-if="!block.removed" v-for="(block, index) in realBlocks" :draggable="true" v-on:dragstart="dragStart(index, $event)" v-on:dragend="dragEnd($event)" :id="'component-block-'+index">-->

    <h2>Pagina blokken</h2>
    <div :class="block.template" v-if="!block.removed" v-for="(block, index) in realBlocks" :id="'component-block-'+index" class="component-block">
        <h3>{{ blockInfo[block.template].label }}<span v-if="block.data.description != undefined">: {{ block.data.description }}</span><span v-if="block.id" style="opacity:0;">#{{ block.id }}</span><span v-else style="opacity:1;">(Zojuist toegevoegd)</span></h3>
        <button class="btn pull-right btn-danger btn-sm" @click.prevent="onComponentRemoved(index)"><i class="fas fa-trash-alt"></i></button>
        <button class="btn pull-right btn-info btn-sm" @click.prevent="moveComponent(index, -1)"><i class="fas fa-arrow-up"></i></button>
        <button class="btn pull-right btn-info btn-sm" @click.prevent="moveComponent(index, 1)"><i class="fas fa-arrow-down"></i></button>
        <button class="btn pull-right btn-primary btn-sm" v-if="openComponent != index" @click.prevent="openComponent = index">Open blok</button>
        <button class="btn btn-secondary btn-sm" v-if="openComponent == index" @click.prevent="openComponent = null">Sluit blok</button>
        <div style="margin-top:10px;"></div>

        <img style="cursor: pointer;"v-if="openComponent != index && blockInfo[block.template]" :src="'/img/'+blockInfo[block.template].image" @click.prevent="openComponent = index"><br />
        <div style="border: 2px dashed rgb(204, 204, 204); border-radius: 5px; padding: 15px;" v-if="openComponent == index">
        <component :is="block.template" :data="block.data" @change="onComponentUpdated(block, index, $event)" :key="'block'+index" ></component>
        </div>
    </div>
</div>
</template>
<style>
    .component-block > img {
        max-width: 100%;
    }
</style>
<script>
    export default {
        props: ['blocks', 'data', 'templates', 'pagedata', 'activeblockindex'],
        data()
        {
            return {
                blockInfo: {
                  BlockKennisbank: {
                    thumb: 'Kennisbank.png',
                    image: 'Kennisbank.png',
                    label: 'Kennisbank'
                  },
                  BlockPakkettenKoppelen: {
                    'thumb': 'Gekoppelde pakketten.png',
                    'image': 'Gekoppelde pakketten.png',
                    label: 'Gekoppelde pakketten'
                  },
                  BlockOpgaveFormulier: {
                    'thumb': 'Opgave formulier.png',
                    'image': 'Opgave formulier.png',
                    label: 'Opgave formulier'
                  },
                  BlockButtonTags: {
                    'thumb': 'Scroll knoppen.png',
                    'image': 'Scroll knoppen.png',
                    label: 'Tagcloud'
                  },
                  BlockImageRightTextLeftGrayBackground: {
                    'image': 'Media blok grijs.png',
                    'thumb': 'Media blok grijs.png',
                    label: 'Media blok grijs'
                  },
                  BlockFullBackgroundWithButtons: {
                    'image': 'Knoppenblok.png',
                    'thumb': 'Knoppenblok.png',
                    label: 'Knoppenblok'
                  },
                  BlockPakketSelectie: {
                    'image': 'Prijs informatie.png',
                    'thumb': 'Prijs informatie.png',
                    label: 'Prijsinformatie'
                  },
                  BlockTwoIconBlocks: {
                    'image': 'Call To Action %2B Icon.png',
                    'thumb': 'Call To Action %2B Icon.png',
                    label: 'Twee Iconen'
                  },
                  BlockTestimonials: {
                    'thumb': 'Logo slideshow.png',
                    'image': 'Logo slideshow.png',
                    label: 'Logo slideshow'
                  },
                  BlockImageLeftAccordionRightGrayBackground: {
                    'image': 'Media uitvouw menu.png',
                    'thumb': 'Media uitvouw menu.png',
                    label: 'Accordion'

                  },
                  BlockVideoLeftTextRight: {
                    'thumb': 'Youtube embed.png',
                    'image': 'Youtube embed.png',
                    label: 'Video blok'
                  },
                  BlockImageRightTextLeft: {
                    'thumb': 'Content media R.png',
                    'image': 'Content media R.png',
                    label: 'Content L Media R'
                  },
                  BlockImageLeftTextRight: {
                    'thumb': 'Content media L.png',
                    'image': 'Content media L.png',
                    label: 'Content R media L'
                  },
                  BlockPopupImageLeftTextRight: {
                    'thumb': 'Popup Content media L.png',
                    'image': 'Popup Content media L.png',
                    label: 'Popup Content R media L'
                  },
                  BlockHeader: {
                    'thumb': 'Header groot.png',
                    'image': 'Header groot.png',
                    label: 'Header groot'
                  },
                  BlockMiniHeader: {
                    'thumb': 'Kleine header.png',
                    'image': 'Kleine header.png',
                    label: 'Header klein'
                  },
                  BlockColorHeader: {
                    'thumb': 'Gekleurde header.png',
                    'image': 'Gekleurde header.png',
                    label: 'Gekleurde header'
                  },
                  BlockUSPList: {
                    'thumb': 'USP list.png',
                    'image': 'USP list.png',
                    label: 'USP blok'
                  },
                  BlockSoftConversion: {
                    'thumb': 'BlockSoftconversie.png',
                    'image': 'BlockSoftconversie.png',
                    label: 'Softconversie'
                  },
                  BlockContactFormulier: {
                    'thumb': 'Formulier contact.png',
                    'image': 'Formulier contact.png',
                    label: 'Contact formulier'
                  },
                  BlockCustomHTML: {
                    'thumb': 'HTML.png',
                    'image': 'HTML_big.png',
                    label: 'HTML/Javascript'
                  }
                },
                realBlocks: this.blocks,
                selectedBlockTemplate: Object.keys(this.templates)[0],
                dragItem: null,
                targetItem: null,
                lastEnterElement: null,
                openComponent: null,
                pages: []
            }
        },
        methods: {
            moveComponent(index, direction)
            {
                let old_index = index;
                let new_index = old_index + direction;
                if(new_index < 0)
                    return;
                if(new_index >= this.realBlocks.length)
                    return;
                this.realBlocks.splice(new_index, 0, this.realBlocks.splice(old_index, 1)[0]);
                this.$emit('change', this.realBlocks);
                this.openComponent = null;
                this.$nextTick(() => {
                    this.openComponent = new_index;
                    let el = document.getElementById("component-block-" + new_index);
                    el.scrollIntoView({
                        behavior: 'smooth'
                    });
                });
            },
            onComponentUpdated(block, blockIndex, data)
            {
                console.log("Component: " + blockIndex + " updated: Here's the data...");
                console.log(data);
                block.data = data;
                this.$emit('change', this.realBlocks);
            },
            onComponentAdded()
            {
                this.realBlocks.push({
                    is: this.selectedBlockTemplate,
                    template: this.selectedBlockTemplate,
                    data: JSON.parse(JSON.stringify(this.templates[this.selectedBlockTemplate]))
                });
                this.$nextTick(() => {
                    this.openComponent = this.realBlocks.length - 1;
                    window.scrollTo({
                        top: document.body.scrollHeight,
                        behavior: 'smooth'
                    });
                });

                //this.$forceUpdate();
                this.$emit('change', this.realBlocks);
            },
            onComponentRemoved(index)
            {
                Vue.set(this.realBlocks[index], 'removed', true);
                //this.realBlocks.splice(index, 1);
                this.$emit('change', this.realBlocks);
            },
            dragStart(index, ev)
            {
            	if(this.dragItem != null)
                {
                	ev.preventDefault();
                    ev.stopPropagation();
                    return false;
				}			
            	this.dragItem = index;
                ev.target.style.opacity = 0.5;
                ev.dataTransfer.setData('text/plain', 'dummy');
            },
            dragEnd(ev)
            {
                ev.target.style.opacity = 1;
                if(this.dragItem == null || this.targetItem == null)
                    return;

                console.log(this.dragItem, this.targetItem);
                let tmp = this.realBlocks.splice(this.dragItem, 1)[0];
                this.realBlocks.splice(this.targetItem, 0, tmp);
                this.$emit('change', this.realBlocks);
                
                this.targetItem = null;
                this.dragItem = null;
                if (this.lastEnterElement !== null)
                {
                    this.lastEnterElement.style.border = "none";
                }
            },
            scrollToOpenBlock()
            {
              let el = $("#component-block-" + this.openComponent)[0];
              if(el != null)
              {
                setTimeout(() => {
                  el.scrollIntoView({behavior:'smooth'});
                }, 600);
              }
            },
            dragEnter(index, ev)
            {
                if(ev.currentTarget.isEqualNode(this.lastEnterElement))
                    return;
                if(this.dragItem == null) return;
                if (this.lastEnterElement !== null)
                {
                    this.lastEnterElement.style.border = "none";
                }
                this.targetItem = index;
                this.lastEnterElement = ev.currentTarget;
                this.lastEnterElement.style.setProperty("border", "5px dashed orange", "important");
            }
        },
        mounted()
        {
            console.log("active block:", this.activeblockindex);
            if(this.activeblockindex != -1)
            {
              this.openComponent = this.activeblockindex;
              this.scrollToOpenBlock();
            }

            console.log(this.blocks);
            console.log(this.templates);
            console.log('Component mounted.');

            const URL = '/website/page/getjsondata';
            axios.get(URL).then((response) => {
                this.pages = response.data.pages;
                console.log(this.pages);
            }).catch((err) => {
                alert("Er is iets fout gegaan met het ophalen van de paginas. Error: " + err);
            });
        }
    }
</script>

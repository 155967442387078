<template>
<div>
    <div class="row">
        <div class="col-xs-12">
          <label>Anchor</label>
          #<input class="form-control inlined" v-model="anchor" v-on:change="onChange" />
        </div>
    </div>
    <input v-model="data.tekst_groot" class="form-control" type="text" disabled /><br />

    <KendoValueComponent v-model="data.tekst_groot" label="Titel" @change="onChange" :tools="tools"/>
    Titel kleur:<br />
    <ColorPicker v-model="data.tekst_groot_color" v-on:input="onChange"></ColorPicker>

    <KendoValueComponent v-model="data.tekst_klein" label="Tekst" @change="onChange" :tools="tools"/>
    Tekst kleur:<br />
    <ColorPicker v-model="data.tekst_klein_color" v-on:input="onChange"></ColorPicker>

    <div class="form-group" style="margin-top:15px;">
        <ImageUploadComponent label="Header afbeelding" @change="onChange($event)" v-model="data.headerImage" />
    </div>
</div>
</template>

<script type="application/javascript">
    export default {
        props: ['data'],
        data()
        {
            return {
                tools: ["cut", "copy", "paste", "bold", "italic", "superscript", "underline", "insertUnorderedList", "insertOrderedList", "createLink", "unlink", {
                        name: "custom",
                        tooltip: "Wordbreak toevoegen",
                        exec: function (e) {
                            var editor = $(this).data("kendoEditor");
                            editor.exec("inserthtml", { value: "&shy;" });
                        }
                    }, "cleanFormatting"],
            }
        },
        methods:
        {
            onChange(e)
            {
                this.$emit('change', this.data);
            },
            onInput(e)
            {
                this.$emit('change', this.data);
            }
        }
    }
</script>
